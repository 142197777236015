import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import StyledPlayerWrapper from '../player-styling'
import {
    useHandleAutoplay,
    usePlayerInitialize,
    useRequestSpotxAd,
    useSendGaEvents,
    useTrackVideoPlays,
    useInsertAdsHtml,
    usePlayerMute,
    useUpdateUrlOnPlaylistItemChange,
} from '../hooks'
import actionTypes from '../state/actionTypes'
import { disableVideoContextMenu, get, whenPlayerReady } from '../utils'
import '../video-js.css'

const setPlaylistOnPlayer = (player, playlist) => {
    const startIndex = 0
    // load playlist and begin playing video at index
    player.playlist(playlist, startIndex)
    // continue to next playlist item immediately after playback of previous ends
    player.playlist.autoadvance(0)
}

const toggleVerticalScrollbarVisibility = (hide = true) => {
    const newVal = hide ? 'hidden' : 'auto'

    document.querySelector('html').style.overflowY = newVal
}

function useToggleVerticalScrollbarVisibility() {
    useEffect(() => {
        toggleVerticalScrollbarVisibility(true)

        return () => {
            toggleVerticalScrollbarVisibility(false)
        }
    }, [])
}

function VodPlayer(props) {
    const adSlotId = 'vod-ad-slot'
    const videoSlotId = 'vod-video-slot'
    const playerId = 'vod-player'

    const { contentViewThreshold, playlist, seriesCode, shouldAutoplay, shouldMute, setVodPlayerMute } = props
    const firstPlaylistItem = playlist[0]
    const firstItemTitle = firstPlaylistItem ? firstPlaylistItem.title : ''

    const player = usePlayerInitialize(firstItemTitle, playerId, true)

    useInsertAdsHtml(player, adSlotId, videoSlotId, playerId)
    useToggleVerticalScrollbarVisibility()

    useEffect(() => {
        whenPlayerReady(player, () => {
            disableVideoContextMenu('vod-player-wrapper')

            setPlaylistOnPlayer(player, playlist)
        })
    }, [contentViewThreshold, playlist, player, seriesCode, shouldAutoplay])

    useUpdateUrlOnPlaylistItemChange(player, true)
    useSendGaEvents(player, contentViewThreshold, seriesCode, null)
    usePlayerMute(player, shouldMute, setVodPlayerMute)
    useRequestSpotxAd(player, undefined, shouldAutoplay, shouldMute, adSlotId, videoSlotId)
    useTrackVideoPlays(player, contentViewThreshold)
    useHandleAutoplay(player, shouldAutoplay, null)

    return (
        <StyledPlayerWrapper id="vod-player-wrapper">
            {/* eslint-disable-next-line */}
            <video
                id={playerId}
                className="vjs-custom-skin video-js hidden vjs-big-play-centered"
                controls
                preload="auto"
            ></video>
        </StyledPlayerWrapper>
    )
}

const mapStateToProps = state => {
    return {
        contentViewThreshold: get(['config', 'contentViewThreshold'], state),
        // default to unmute
        shouldMute: typeof state.vodPlayerMute !== 'undefined' ? state.vodPlayerMute : false,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setVodPlayerMute: shouldMute => dispatch({ type: actionTypes.SET_VOD_PLAYER_MUTE, mute: shouldMute }),
    }
}

const ConnectedVodPlayer = connect(mapStateToProps, mapDispatchToProps)(VodPlayer)

export default ConnectedVodPlayer
