import { navigate } from 'gatsby'
import React, { useEffect, useState } from 'react'
import VodPlayer from '../components/vod-player'
import EpgPlayer from '../components/epgPlayer'
import StreamChannel from '../components/EPG/models/StreamChannel'
import SimulatedLinearChannel from '../components/EPG/models/SimulatedLinearChannel'
import remocoClient from '../remocoClient'
import { getSimulatedLinearChannelData } from '../components/EPG/utils/EPGUtils'
import { get, getAssetData, getIdentifierFromPath, getRelevantEntriesData } from '../utils'
import SEO from '../components/seo'

function nothingToPlay(playerProps) {
    const hasNoProps = Object.keys(playerProps).length === 0

    if (hasNoProps) {
        return true
    }

    const { isChannel, source } = playerProps

    if (!isChannel && !source) {
        return true
    }
    // TODO: check that channel is playable
}

async function getSeriesItems(seriesId, id) {
    const seriesData = await remocoClient.getSeriesById(seriesId, 2, '1,25').catch(err => {
        console.error('Error retrieving series:', seriesId, 'for item:', id, 'Error:', err)
        navigate('/404')
    })

    return get(['data', 'events', 'list'], seriesData) || []
}

async function getCategoryItems(categoryId, id) {
    // in cases where category ID contains multiple categories joined with a colon, e.g. "producer:prometheus:billboard",
    // just use the first, as Remoco API can't handle this format
    const cat = categoryId.includes(':') ? categoryId.split(':')[0] : categoryId

    const categoryData = await remocoClient.getCategoryEvents(cat, 2, '25').catch(err => {
        console.error('Error retrieving related videos by category:', categoryId, 'for item:', id, 'Error:', err)
        navigate('/404')
    })

    return get(['list'], categoryData) || []
}

async function getRelatedItemsOfType(getRelatedFn, typeId, assetId, relatedTitle) {
    const relatedData = await getRelatedFn(typeId, assetId)
    const filtered = removeCurrentPageVideo(relatedData, assetId)

    return getRelevantEntriesData(filtered)
}

function removeCurrentPageVideo(list = [], id) {
    return list.filter(v => {
        // filter out the current page's video
        return v.id !== id
    })
}

async function getRelatedItems(id, seriesId, categoryId, categoryTitle, seriesTitle) {
    let relatedItems = []

    if (seriesId) {
        relatedItems = await getRelatedItemsOfType(getSeriesItems, seriesId, id, seriesTitle)
    } else if (categoryId) {
        relatedItems = await getRelatedItemsOfType(getCategoryItems, categoryId, id, categoryTitle)
    } else {
        console.warn('No related items found for asset with ID:', id)
    }

    return relatedItems
}

const getSourceType = source => {
    let type = 'video/mp4'

    if (source && source.match(/\.m3u8$/g)) {
        type = 'application/x-mpegURL'
    }

    return type
}

const getRelevantPlaylistItemData = item => {
    if (!item.source) {
        return
    }

    const { ctitle = '', source, image = '', id, eventCode, seriesId, series, slug } = item
    const type = getSourceType(source)

    return {
        eventCode,
        id,
        poster: image,
        seriesId: seriesId || series,
        sources: [{ src: source, type }],
        slug,
        title: ctitle,
    }
}

const filterOutPlaylistItemsWithNoSource = playlist => {
    return playlist.filter(item => {
        return !!get(['sources', 0, 'src'], item)
    })
}

const getPlaylist = async props => {
    const { autoplayList, category, series, categoryTitle, seriesTitle, id } = props

    const list = autoplayList || (await getRelatedItems(id, series, category, categoryTitle, seriesTitle))
    const relevantPlaylistData = list.map(getRelevantPlaylistItemData)
    const allPlaylistData = [getRelevantPlaylistItemData(props), ...relevantPlaylistData]

    return filterOutPlaylistItemsWithNoSource(allPlaylistData)
}

const getChannel = async playerProps => {
    const { image, ctitle, eventCode, id, source, subtype } = playerProps

    let channel

    if (subtype === 'channel-collection') {
        const data = await getSimulatedLinearChannelData(eventCode)

        channel = new SimulatedLinearChannel(...data, image, ctitle, eventCode, id, subtype)
    } else if (subtype === 'channel-stream') {
        channel = new StreamChannel(source, image, ctitle, eventCode, id, subtype)
    }

    return channel
}

const getAssetDataUsingSlug = location => {
    const id = getIdentifierFromPath({ location })

    return getAssetData(id)
}

const getPlayer = (channel, isChannel, playlist, playerProps) => {
    if (isChannel && channel) {
        return <EpgPlayer channel={channel} shouldAutoplay shouldHideControls={false} shouldMute={false} />
    }

    return <VodPlayer playlist={playlist} {...playerProps} shouldAutoplay shouldMute={false} />
}

const useGetPlayerProps = location => {
    const [playerProps, setPlayerProps] = useState({})

    useEffect(() => {
        let locationProps = get(['state'], location)

        if (locationProps && typeof locationProps === 'object' && Object.keys(locationProps).length > 0) {
            setPlayerProps(locationProps)
        } else {
            getAssetDataUsingSlug(location)
                .then(setPlayerProps)
                .catch(err => {
                    navigate('/404')
                })
        }
    }, [location])

    return playerProps
}

const Player = ({ location }) => {
    const [isLoading, setIsLoading] = useState(true)
    const [channel, setChannel] = useState(null)
    const [playlist, setPlaylist] = useState([])

    const playerProps = useGetPlayerProps(location)

    useEffect(() => {
        if (Object.keys(playerProps).length > 0) {
            if (playerProps.isChannel) {
                getChannel(playerProps).then(ch => {
                    setChannel(ch)

                    setIsLoading(false)
                })
            } else {
                getPlaylist(playerProps).then(p => {
                    setPlaylist(p)

                    setIsLoading(false)
                })
            }
        }
    }, [playerProps])

    if (isLoading) {
        return null
    }

    if (nothingToPlay(playerProps)) {
        navigate('/404')
    }

    return (
        <>
            <SEO
                title={playerProps.ctitle}
                description={playerProps.description}
                url={location.href}
                image={playerProps.image}
            />
            {getPlayer(channel, playerProps.isChannel, playlist, playerProps)}
        </>
    )
}

export default Player
